<template>
  <div>
    <v-container>
      <div class="d-flex align-center justify-center header-brands">
        <img
          loading="lazy"
          height="160px"
          src="/img/grupo-fasipe.png"
          alt="Grupo Fasipe Educacional"
          class="d-block brand-group"
        />

        <img
          loading="lazy"
          height="73px"
          src="/img/separator.png"
          class="mx-5 mx-lg-16 separator"
        />

        <img
          loading="lazy"
          height="217px"
          src="/img/20-anos-fasipe.png"
          alt="20 anos Fasipe"
          class="d-block age"
        />
      </div>

      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          md="8"
          lg="4"
          class="mt-10 grey lighten-4 pa-5 pt-4"
        >
          <v-form>
            <div
              class="
                title
                grey--text
                text--darken-1 text-center
                font-weight-light
                mb-3
              "
            >
              ESCOLHA SUA UNIDADE
            </div>

            <v-select
              background-color="white"
              outlined
              hide-details
              v-model="goTo"
              :items="items"
              label="ESCOLHA SUA UNIDADE"
            ></v-select>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-4 mt-md-6 mt-lg-12" fluid>
      <v-row justify="space-around">
        <template v-for="(item, i) in [...faculties, ...schools]">
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mt-4" :key="i">
            <Brands :item="item" />
          </v-col>

          <v-responsive
            v-if="(xl && i === 4) || (lg && i === 3)"
            :key="`width-${i}`"
            width="100%"
          ></v-responsive>
        </template>
      </v-row>
    </v-container>

    <div
      class="
        text-center
        mt-10 mt-md-10 mt-lg-16
        text-center text-uppercase
        grey--text
        text text-body-1 text-sm-h5 text-md-h4 text-lg-h3
        font-weight-thin
      "
    >
      www.grupofasipe.com.br
    </div>
  </div>
</template>

<script>
import { getTelLinkFromPhone } from "../utils";

const _faculties = [
  {
    title: "SINOP FLORENÇA - FASIP/FASIPE",
    img: "/img/units/faculties/unifasipe+fasipe.png",
    imgWidth: "300px",
    link: "https://fasipe.com.br/?lc=florenca",
    local: "RESIDENCIAL FLORENÇA - SINOP/MT",
    address: [
      "Av. Magda Cassia Pissinati Nº 69",
      "Residencial Florença - Sinop - MT",
    ],
    tel: [
      {
        num: "(66) 3517-1320",
        link: getTelLinkFromPhone("(66) 3517-1320"),
      },
      {
        num: "(66) 9 9657-5964",
        link: getTelLinkFromPhone("(66) 9 9657-5964"),
      },
    ],
  },
  {
    title: "SINOP AQUARELA - FASIP/FASIPE",
    img: "/img/units/faculties/fasipe.png",
    imgWidth: "300px",
    link: "https://fasipe.com.br/?lc=aquarela",
    local: "AQUARELA DAS ARTES - SINOP/MT",
    address: [
      "Rua Graciliano Ramos, S/N, Lote 78D",
      "Aquarela das Artes - Sinop - MT",
    ],
    tel: [
      {
        num: "(66) 3517-1320",
        link: getTelLinkFromPhone("(66) 3517-1320"),
      },
      {
        num: "(66) 9 9657-5964",
        link: getTelLinkFromPhone("(66) 9 9657-5964"),
      },
    ],
  },
  {
    title: "CUIABÁ - FASIPE CPA",
    img: "/img/units/faculties/fasipe-cuiaba.png",
    imgWidth: "250px",
    link: "https://fasipecuiaba.com.br",
    local: "CPA - CUIABÁ/MT",
    address: [
      "Rua Jornalista Amaro de Figueiredo",
      "Falcão, 133 - Cpa I, Cuiabá - MT",
    ],
    tel: [
      {
        num: "(65) 3548-3900",
        link: getTelLinkFromPhone("(65) 3548-3900"),
      },
      {
        num: "(65) 9 8464-0233",
        link: getTelLinkFromPhone("(65) 9 8464-0233"),
      },
    ],
  },
  {
    title: "RONDONÓPOLIS - FASIPE RONDONÓPOLIS",
    img: "/img/units/faculties/fasipe-rondonopolis.png",
    imgWidth: "250px",
    link: "https://fasiperondonopolis.com.br",
    local: "RONDONÓPOLIS/MT",
    address: [
      "Av. Flavio Alves de Medeiros Nº 64",
      "Parque Sagrada Familia I Rondonópolis - MT",
    ],
    tel: [
      {
        num: "(66) 3302-0099",
        link: getTelLinkFromPhone("(66) 3302-0099"),
      },
      {
        num: "(66) 9 9725-6560",
        link: getTelLinkFromPhone("(66) 9 9725-6560"),
      },
    ],
  },
  {
    title: "SORRISO - FASIPE SORRISO",
    img: "/img/units/faculties/fasipe-sorriso.png",
    imgWidth: "250px",
    link: "https://fasipesorriso.com.br",
    local: "SORRISO/MT",
    address: [
      "Perimetral Sudoeste, 3675",
      "Bairro Flor do Cerrado - Sorriso - MT",
    ],
    tel: [
      {
        num: "(66) 9 9254-4850",
        link: getTelLinkFromPhone("(66) 9 9254-4850"),
      },
      {
        num: "(66) 99254-2386",
        link: getTelLinkFromPhone("(66) 99254-2386"),
      },
    ],
  },
  {
    title: "PRIMAVERA DO LESTE - FASIPE PRIMAVERA",
    img: "/img/units/faculties/fasipe-primavera.png",
    imgWidth: "250px",
    link: "https://fasipeprimavera.com.br",
    local: "PRIMAVERA DO LESTE/MT",
    address: [
      "Avenida Campo Grande, nº 1238",
      "Centro - Primavera do Leste -MT",
    ],
    tel: [
      {
        num: "(66) 9 9206-6647",
        link: getTelLinkFromPhone("(66) 9 9206-6647"),
      },
      {
        num: "(66) 9 9206-2418",
        link: getTelLinkFromPhone("(66) 9 9206-2418"),
      },
    ],
  },
  {
    title: "DISTRITO FEDERAL - FASIPE DISTRITO FEDERAL",
    img: "/img/units/faculties/fasipedf.png",
    imgWidth: "250px",
    link: "https://fasipedf.com.br",
    local: "DISTRITO FEDERAL/DF",
    address: ["St. QI 20 ", "Taguatinga, Brasília - DF"],
    tel: [
      {
        num: "(61) 98545-3536",
        link: getTelLinkFromPhone("(61) 98545-3536"),
      },
    ],
  },
  // {
  //   title: "BRASÍLIA - FASIPE DF",
  //   img: "/img/units/faculties/fasipe-df.svg",
  //   imgWidth: "250px",
  //   link: "https://fasipedf.com.br",
  //   local: "BRASÍLIA/DF",
  //   address: [
  //     "Avenida Campo Grande, nº 1238",
  //     "Centro - Primavera do Leste -MT",
  //   ],
  //   tel: [
  //     {
  //       num: "(66) 9 9206-6647",
  //       link: getTelLinkFromPhone("(66) 9 9206-6647"),
  //     },
  //     {
  //       num: "(66) 9 9206-2418",
  //       link: getTelLinkFromPhone("(66) 9 9206-2418"),
  //     },
  //   ],
  // },
  {
    title: "CUIABÁ - FASIPE FERNANDO CORRÊA",
    img: "/img/units/faculties/fasipe-fernando-correa.png",
    imgWidth: "250px",
    local: "CUIABÁ/MT",
    address: ["Av. Fernando Corrêa da Costa, 3677", "Coxipó, Cuiabá - MT"],
  },
];

const _schools = [
  {
    title: "SORRISO - COLÉGIO SAN PETRUS",
    img: "/img/units/schools/san-petrus.png",
    imgWidth: "170px",
    link: "https://www.colegiosanpetrus.com.br/index.php?c=a",
    local: "SORRISO/MT",
    address: ["Rua São Silvestre, 1636", "Jardim Flor do Cerrado - Sorriso-MT"],
    tel: [
      {
        num: "(66) 9 9656-1297",
        link: getTelLinkFromPhone("(66) 9 9656-1297"),
      },
    ],
  },
  {
    title: "SINOP - COLÉGIO SAN PETRUS",
    img: "/img/units/schools/san-petrus.png",
    imgWidth: "170px",
    link: "https://www.cspsinop.com.br",
    local: "SINOP/MT",
    address: ["Av. Magda de C. Pissinatti, Nº 69", "Residencial Florença"],
    tel: [
      {
        num: "(66) 99963-1919",
        link: getTelLinkFromPhone("(66) 99963-1919"),
      },
    ],
  },
  {
    title: "CUIABÁ - COLÉGIO SAN PETRUS",
    img: "/img/units/schools/san-petrus.png",
    imgWidth: "170px",
    local: "CUIABÁ/MT",
  },
];

import Brands from "../cards/Brands.vue";

export default {
  data: () => ({
    goTo: "",
    faculties: _faculties,
    schools: _schools,
  }),
  computed: {
    lg() {
      return this.$vuetify.breakpoint.lgOnly;
    },
    xl() {
      return this.$vuetify.breakpoint.xlOnly;
    },
    items() {
      return [
        ...[{ header: "Faculdades" }],
        ...this.faculties.map(({ title, link }, index) => ({
          value: link ? link : index,
          text: title,
          disabled: !link ? true : false,
        })),
        ...[{ header: "Colégios" }],
        ...this.schools.map(({ title, link }, index) => ({
          value: link ? link : index,
          text: title,
          disabled: !link ? true : false,
        })),
      ];
    },
  },
  watch: {
    goTo() {
      location = this.goTo;
    },
  },
  components: {
    Brands,
  },
};
</script>

<style scoped>
@media (max-width: 960px) {
  .header-brands .brand-group {
    height: 112px;
  }

  .header-brands .separator {
    height: 51px;
  }

  .header-brands .age {
    height: 152px;
  }
}

@media (max-width: 600px) {
  .header-brands .brand-group {
    height: 80px;
  }

  .header-brands .separator {
    height: 36.5px;
  }

  .header-brands .age {
    height: 108.5px;
  }
}

.text {
  letter-spacing: 10px !important;
}

@media screen and (max-width: 600px) {
  .text {
    letter-spacing: 5px !important;
  }
}
</style>
