<template>
  <div>
    <img
      loading="lazy"
      :src="item.img"
      :alt="item.title"
      class="d-flex mx-auto"
      :width="item.imgWidth"
    />

    <div
      class="text-body-1 font-weight-bold text-center mt-4"
      v-if="item.local"
    >
      {{ item.local }}
    </div>

    <div class="mt-2" v-if="item.address">
      <div
        v-for="(text, key) in item.address"
        :key="key"
        class="
          text-body-2
          font-weight-medium
          grey--text
          text--darken-2 text-center
        "
      >
        {{ text }}
      </div>
    </div>

    <div class="mt-2 d-flex justify-center" v-if="item.tel">
      <div v-for="({ num, link }, key) in item.tel" :key="key">
        <div v-if="key > 0" class="mx-2 d-inline-flex">•</div>
        <a
          :href="link"
          class="
            text-body-2
            font-weight-bold
            grey--text
            text--darken-4 text-decoration-none
          "
        >
          {{ num }}
        </a>
      </div>
    </div>

    <div v-if="!item.link" class="text-center">
      <v-chip
        color="yellow"
        class="text-caption grey--text text--darken-4 font-weight-bold mt-2"
      >
        EM BREVE...
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
img {
  height: auto;
  max-width: 100%;
}
</style>
